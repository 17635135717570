import { Flex, Select } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { getRelativeYears } from '@/utils/dates';

type Props = {
  date: DateTime;
  onSelect: (year: number) => void;
};

export const YearSelect = ({ date, onSelect }: Props) => {
  const options = getRelativeYears(date, 3, 3).map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));

  return (
    <Flex align="center" textAlign="left">
      <Select
        size="sm"
        value={date.year}
        onChange={(event) => {
          const value = Number(event.target.value);
          onSelect(value);
        }}
      >
        {options}
      </Select>
    </Flex>
  );
};
