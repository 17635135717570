import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  MenuButtonProps,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { DateTime, Interval } from 'luxon';
import { useMemo } from 'react';
import { useIsBreakpoint } from '@/hooks/useIsBreakpoint';
import { YearSelect } from './YearSelect';

type Props = Omit<MenuButtonProps, 'onChange'> & {
  startDate: DateTime;
  onChange: (date: DateTime) => void;
};

export const MonthPicker = ({ startDate, onChange }: Props) => {
  const smallDateFormat = useIsBreakpoint('sm');

  const months = useMemo(
    () =>
      Interval.fromDateTimes(
        startDate.startOf('year'),
        startDate.endOf('year')
      ).splitBy({ months: 1 }),
    [startDate]
  );

  return (
    <Menu isLazy>
      {({ onClose }) => (
        <>
          <Button as={MenuButton} variant="secondary">
            {startDate.toFormat(smallDateFormat ? 'MMM y' : 'MMMM yyyy')}
          </Button>
          <Portal>
            <MenuList p="2">
              <YearSelect
                date={startDate}
                onSelect={(year) => {
                  onChange(startDate.set({ year }));
                  onClose();
                }}
              />
              <Grid gap="2" mt="2" templateColumns="repeat(3, 1fr)">
                {months.map(({ start }) => {
                  const isSelected = start.month === startDate.month;

                  return (
                    <GridItem
                      _hover={{ bg: 'gray.100' }}
                      as="button"
                      borderRadius="md"
                      fontSize="sm"
                      key={start.month}
                      py="1"
                      textAlign="center"
                      {...(isSelected && {
                        bg: 'gray.200',
                        fontWeight: 'medium',
                        _hover: { bg: 'gray.200' },
                      })}
                      onClick={() => {
                        onChange(startDate.set({ month: start.month }));
                        onClose();
                      }}
                    >
                      {start.toFormat('MMM')}
                    </GridItem>
                  );
                })}
              </Grid>
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  );
};
